<template>
  <div class="select" :class="[getShowOptions, getWidth, setLayoutColor, getSearchableClass, getMultipleClass]" :id="id" ref="select">
    <div class="select__field" @click="toggleMenu">
      <template v-if="selectedMultiple.length">
        <div v-for="selected in selectedMultiple" :key="selected.id" class="selected-item">
          <p class="selected-item__text">{{ selected.name }}</p>
          <button type="button" class="selected-item__remove" @click.stop="removeSelectedOption(selected)">
            <img width="10" height="10" :src="require(`@/assets/images/close${layoutColor === 'red' ? '-red' : ''}.svg`)" alt="Remove" />
          </button>
        </div>
      </template>
      <p v-show="selectedOption && !searching" class="select__result select__result_selected">
        {{ selectedOption }}
      </p>
      <p v-show="!selectedOption && !selectedMultiple.length && !searching" class="select__result-placeholder">
        {{ placeholder }}
      </p>
      <div v-show="searching" class="select__search">
        <input
          v-model="searchQuery"
          :placeholder="searchPlaceholder"
          class="select__search-input"
          type="text"
          ref="searchInput"
          @click.stop
        />
        <button v-if="searchQuery" type="button" class="select__search-clear" @click.stop="clearSearch">
          <img width="15" height="15" :src="require(`@/assets/images/close${layoutColor === 'red' ? '-red' : ''}.svg`)" alt="Remove" />
        </button>
      </div>
      <div class="select__options" :class="getSize">
        <label class="select__option" v-for="item in finallyOptions" :key="item.id">
          <input
            class="select__input"
            name="select"
            :class="optionTypeClass"
            :type="optionType"
            :id="id"
            :value="item.name"
            :required="required"
            :checked="isSelectedOption(item)"
            :disabled="selectedMultiple.length >= maxMultiple && !isSelectedOption(item)"
            @change="onSelect($event, item)"
          />
          <span class="select__result">
            {{ item.name }}
          </span>
        </label>
        <span v-if="searchable && !finallyOptions.length" class="select__not-found">{{ notFoundText }}</span>
      </div>
    </div>
    <div v-if="promptText" class="select__prompt">
      {{ promptText }}
    </div>
  </div>
</template>

<script>
import search from "@/components/ui/Select/mixins/search";
import multiple from "@/components/ui/Select/mixins/multiple";

export default {
  name: "SelectInput",
  mixins: [search, multiple],
  props: {
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: String,
    },
    id: {
      type: String,
    },
    value: {
      type: [String, Number, Array],
    },
    isSmall: {
      type: Boolean,
    },
    width: {
      type: String,
    },
    promptText: {
      type: String,
    },
  },
  data() {
    return {
      showOptions: false,
      selectedOption: null,
    };
  },
  created() {
    window.addEventListener("click", this.close);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.selectedOption = null;
      }
    },
  },
  computed: {
    getSize() {
      return this.isSmall ? "select__options-small" : "";
    },
    getWidth() {
      return this.width ? `select_${this.width}` : "";
    },
    getShowOptions() {
      return this.showOptions ? "select__options_show" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `select_${this.layoutColor}`;
    },
    finallyOptions() {
      let result = this.options;

      if (this.searchQuery && this.searchable) {
        result = this.filterByQuery(result, this.searchQuery);
      }

      if (this.selectedMultiple.length) {
        result = this.filterBySelected(result, this.selectedMultiple);
      }

      return result;
    },
    optionType() {
      return this.multiple ? "checkbox" : "radio";
    },
    optionTypeClass() {
      return `select__input_${this.optionType}`;
    },
  },
  methods: {
    onSelect(evt, item) {
      this.multiple ? this.onSelectMultiple(evt, item) : this.onSelectSingle(item);
    },
    onSelectSingle(item) {
      this.selectedOption = item.name;
      this.showOptions = false;
      this.searchQuery = item.name;

      this.emit(item);
    },
    toggleMenu() {
      if (this.selectedMultiple.length >= this.maxMultiple) return;

      this.showOptions = !this.showOptions;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showOptions = false;
      }
    },
    emit(item) {
      if (this.multiple) {
        this.$emit("input", this.selectedMultiple);
      } else {
        switch (this.id) {
          case "sum":
          case "curator":
          case "certificate":
          case "segment":
          case "type":
            this.$emit("input", item.id);
            break;
          case "nominal":
            this.$emit("nominalValue", { id: item.id, value: item.value });
            break;
          case "segmentModal":
            this.$emit("input", item.name);
            this.$emit("change");
            break;
          default:
            this.$emit("input", item.name);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  max-width: 250px;
  width: 100%;
  position: relative;
  background: transparent;
  cursor: pointer;

  &_purple {
    border-color: rgba(76, 65, 203, 0.5);

    &_error {
      border-color: $color-red;

      &:focus {
        border-color: $color-red;
      }
    }

    & .select__option {
      color:  $color-purple-40;
      font-weight: 500 !important;

      &:hover {
        color: $color-purple-20 !important;
      }
    }

    & .select__result-placeholder,
    & .select__search-input::placeholder {
      color: $color-purple-40;
      font-weight: 500;
    }

    & .select__search-input,
    & .select__not-found {
      color: $color-purple-20;
    }

    @media screen and (max-width: 680px) {
      .select__result,
      .select__result_selected,
      .select__search-input {
        color: $color-purple-40;
      }
    }
  }

  &_red {
    border-color: rgba(176, 6, 51, 0.5);

    &_error {
      border-color: $color-red;

      &:focus {
        border-color: $color-red;
      }
    }

    & .select__option {
      color: $color-red-30;
      font-weight: 500 !important;

      &:hover {
        color: $color-red-20;
      }
    }

    & .select__result-placeholder,
    & .select__search-input::placeholder {
      color: $color-red-30;
      font-weight: 500;
    }

    & .select__search-input,
    & .select__not-found {
      color: $color-red-20;
    }

    @media screen and (max-width: 680px) {
      .select__result,
      .select__result_selected,
      .select__search-input {
        color: $color-red-30;
      }
    }
  }

  &_max {
    max-width: 100%;
  }

  &_multiple {
    .select__field {
      height: auto;
      min-height: 48px;

      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &_error,
  &_disable {
    border-color: $color-red-10;

    &:focus {
      border-color: $color-red-10;
    }
  }

  &__field {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    height: 48px;

    padding-left: 12px;
    padding-right: 30px;

    border-width: 1px;
    border-style: solid;
    border-color: inherit;

    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      display: flex;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      background: url("~@/assets/images/select.svg") no-repeat center center;
      width: 14px;
      height: 14px;
      transition: $transition-main;
      cursor: pointer;
      pointer-events: none;
    }
  }

  &__options {
    transition: $transition-main;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    width: 100%;
    max-height: 328px;
    overflow-y: scroll;
    background-color: $color-white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px 0;
    z-index: 4;

    &-small {
      max-height: 100px;
    }
  }

  &__options_show {
    &::before {
      transform: rotate(-180deg) translateY(50%);
    }

    .select__options {
      opacity: 1;
      visibility: visible;
    }
  }

  &__option {
    position: relative;
    padding: 5px 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__input:disabled ~ .select__result {
    color: $color-red;
  }

  &__input_radio,
  &__input_checkbox {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }

  &__result {
    font-size: 14px;
    line-height: 20px;

    &_selected {
      font-weight: 500;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    flex: 1;

    &-input {
      min-width: 130px;
      width: 100%;
      height: 100%;
      font-weight: 500;
    }

    &-clear {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__not-found {
    padding: 5px 12px;
  }

  &__result-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__prompt {
    margin-top: 5px;
    color: currentColor;
  }

  &_searchable {
    cursor: text;
  }

  .selected-item {
    display: flex;
    gap: 5px;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 2px;
    border: 1px solid currentColor;

    &__remove {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .select__result,
  .select__search-input {
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 573px) {
  .select {
    max-width: 100%;
  }
}
</style>
