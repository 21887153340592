<template>
  <button class="button" :class="[getColor, disabled, setLayoutColor]" :type="type" :disabled="isLoading" @click="sendEvent">
    <img v-if="iconBefore" class="button__icon_before" :src="iconBefore" />
    <div class="button__box">
      <p v-if="title" class="button__title">
        {{ title }}
      </p>
      <div v-if="disabledText && isDisabled" class="button__disabled_text">
        {{ disabledText }}
      </div>
    </div>
    <img v-if="iconAfter" class="button__icon_after" :src="iconAfter" />
    <div v-if="isLoading" class="button__loading">
      <v-progress-circular indeterminate />
    </div>
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    iconBefore: {
      type: String,
    },
    iconAfter: {
      type: String,
    },
    type: {
      type: String,
    },
    color: {
      type: String,
      default: "main",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
    },
  },
  computed: {
    getColor() {
      return `button_${this.color}`;
    },
    disabled() {
      return this.isDisabled ? "button__disabled" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `button_layout_${this.layoutColor}`;
    },
  },
  methods: {
    sendEvent() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 50px;
  cursor: pointer;
  transition: $transition-main;
  position: relative;

  &_layout_purple {
    &.button_yellow {
      background-color: $color-yellow;
      color: $color-purple-30;
    }

    &.button_yellow-round {
      background-color: $color-yellow;
      color: $color-purple-30;
    }

    &.button_transparent-yellow {
      color: $color-yellow;
      border: 1px solid $color-yellow;

      &:hover {
        color: $color-purple-40;
        background-color: $color-yellow;
      }
    }

    &.button_transparent-purple {
      color: $color-purple-20;
      border: 1px solid $color-purple-20;
    }

    &.button_purple {
      background-color: $color-purple-20;

      &:hover {
        background-color: $color-purple-10;
      }
    }

    &.button_transparent {
      color: $color-purple-20;
    }

    &.button_form {
      color: rgba(130, 119, 255, 0.6);
      border: 1px solid rgba(130, 119, 255, 0.6);

      &:hover {
        background-color: rgba(130, 119, 255, 0.6);
      }
    }
  }

  &_layout_red {
    &.button_yellow {
      background-color: $color-dark-yellow;
      color: $color-red-30;
    }

    &.button_yellow-round {
      background-color: $color-dark-yellow;
      color: $color-red-30;
    }

    &.button_transparent-yellow {
      color: $color-dark-yellow;
      border: 1px solid $color-dark-yellow;

      &:hover {
        color: $color-red-30;
        background-color: $color-dark-yellow;
      }
    }

    &.button_transparent-purple {
      color: $color-red-30;
      border: 1px solid $color-red-30;
    }

    &.button_purple {
      background-color: $color-red-30;

      &:hover {
        background-color: $color-red-20;
      }
    }

    &.button_transparent {
      color: $color-red-30;
    }

    &.button_form {
      color: rgba(176, 6, 51, 0.6);
      border: 1px solid $color-red-10;

      &:hover {
        background-color: $color-red-10;
      }
    }
  }

  &_yellow {
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  &_yellow-round {
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
  }

  &_yellow-red {
    border-radius: 50px;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff72c;
    color: #ff0000;
  }

  &_transparent-yellow {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
  }

  &_transparent-purple {
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
  }

  &_purple {
    color: $color-white;
    font-weight: 500;

    & > .button__box {
      display: block;
      position: relative;

      & > .button__disabled_text {
        position: absolute;
        width: 200px;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
      }
    }
  }

  &_transparent {
    &.button__disabled {
      opacity: 100%;
      color: $color-grey;
    }
  }

  &_form {
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border-radius: 10px;

    &:hover {
      color: $color-white;
    }
  }

  &__icon {
    &_before {
      margin-right: 10px;
    }
  }

  &__disabled {
    opacity: 50%;
    pointer-events: none;

    &_text {
      color: $color-red-10;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__loading {
    position: absolute;
    right: 10px;
  }
}
</style>
