<template>
  <a
    v-if="route && (title === 'Каталог' || title === 'Гарантия')"
    :href="route"
    target="_blank"
    class="link"
    :class="[setLayoutColor, isActive, isBurger]"
  >
    {{ title }}
  </a>
  <router-link v-else-if="route" :to="route" class="link" :class="[setLayoutColor, isActive, isBurger]" @click.native="$emit('click')">
    {{ title }}
    <div v-if="unread" class="link__unread">
      {{ unread }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MenuLink",
  props: {
    route: {
      type: String,
    },
    title: {
      type: String,
    },
    unread: [String, Number],
    active: {
      type: Boolean,
      default: false,
    },
    burger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      return this.active ? "link_active" : "";
    },
    isBurger() {
      return this.burger ? "link_burger" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `link_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 17px;
  cursor: pointer;
  width: fit-content;
  @include font("CeraPro", 18px, 400);
  position: relative;

  &_active {
    border-radius: 20px;
    padding: 6px 10px;
  }

  &_purple {
    &.link_active {
      background-color: $color-yellow;
      color: $color-purple-40;
    }
  }

  &_red {
    &.link_active {
      background-color: $color-dark-yellow;
      color: $color-red-30;
    }
  }

  &_burger {
    width: 100%;
    padding: 14px 0;
    padding-right: 28px;
    border-radius: 0;
    justify-content: flex-end;
  }

  &__unread {
    position: absolute;
    width: 19px;
    height: 19px;
    background-color: $color-yellow;
    color: $color-purple-40;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    top: -18px;
    right: -10px;
  }
}

@media screen and (max-width: 1530px) {
  .link__unread {
    top: 0;
    right: 6px;
  }
}
</style>
