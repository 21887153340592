<template>
  <div class="header">
    <div class="header__container">
      <div class="header__logo">
        <div class="header__logo_logo">
          <router-link to="/" class="logo" @click.native="scrollToTop">
            <img src="@/assets/images/brixo-logo.svg" alt="BRIXO" />
          </router-link>
        </div>
      </div>
      <div class="header__switch">
        <SwitchButton />
      </div>
      <div class="header__menu_desktop">
        <div v-if="userRole === 'curator'" class="header__menu header__menu_gap">
          <MenuLink
            v-for="(item, index) in getMenuCurator"
            :key="'H' + index"
            :route="item.link"
            :title="item.name"
            :unread="item.unread"
            :active="findActiveRoute(item.link)"
          />
          <div class="header__menu_rules">
            <DownloadLink
              text="Правила участника"
              :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Curator_rules.pdf'"
              color="white"
            />
          </div>
          <div v-if="isAuth" class="header__menu_logout">
            <MainButton title="Выйти" color="transparent-yellow" @click="$emit('logout')" />
          </div>
        </div>
        <div v-else-if="userRole === 'manager'" class="header__menu header__menu_gap">
          <MenuLink
            v-for="(item, index) in getMenuSales"
            :key="'K' + index"
            :route="item.link"
            :title="item.name"
            :unread="item.unread"
            :active="findActiveRoute(item.link)"
          />
          <div class="header__menu_rules">
            <DownloadLink
              text="Правила участника"
              :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Sales_rules.pdf'"
              color="white"
            />
          </div>
          <div v-if="isAuth" class="header__menu_logout">
            <MainButton title="Выйти" color="transparent-yellow" @click="$emit('logout')" />
          </div>
        </div>
        <div v-else-if="userRole === 'member' && this.userProfile && !this.userProfile.is_dealer" class="header__menu header__menu_gap">
          <MenuLink
            v-for="(item, index) in menuRetail"
            :key="'L' + index"
            :route="item.link"
            :title="item.name"
            :unread="item.unread"
            :active="findActiveRoute(item.link)"
          />
          <div class="header__menu_rules">
            <DownloadLink
              text="Правила участника"
              :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Retail_rules.pdf'"
              color="white"
            />
          </div>
          <div v-if="isAuth" class="header__menu_logout">
            <MainButton title="Выйти" color="transparent-yellow" @click="$emit('logout')" />
          </div>
        </div>
        <div v-else-if="userRole === 'member' && this.userProfile && this.userProfile.is_dealer" class="header__menu header__menu_gap">
          <MenuLink
            v-for="(item, index) in menuRetail"
            :key="'L' + index"
            :route="item.link"
            :title="item.name"
            :unread="item.unread"
            :active="findActiveRoute(item.link)"
          />
          <div class="header__menu_rules">
            <DownloadLink
              text="Правила участника"
              :href="'https://bonus.brixogroup.com/upload/email/BRIXO_BONUS_Dealer_rules.pdf'"
              color="white"
            />
          </div>
          <div v-if="isAuth" class="header__menu_logout">
            <MainButton title="Выйти" color="transparent-yellow" @click="$emit('logout')" />
          </div>
        </div>
        <div v-else class="header__menu">
          <MenuLink
            v-for="(item, index) in menuLinks"
            :key="'E' + index"
            :route="item.link"
            :title="item.name"
            :unread="item.unread"
            :active="findActiveRoute(item.link)"
          />
        </div>
      </div>
      <div class="header__menu_button">
        <BurgerButton @click="toggleMenu" />
      </div>
      <div class="header__menu_mobile" :class="[setLayoutColor]" id="burger-menu">
        <div class="burger__button">
          <MainButton
            v-if="layoutColor === 'purple'"
            :iconAfter="require('@/assets/images/close.svg')"
            :isIcon="true"
            @click="toggleMenu"
          />
          <MainButton
            v-if="layoutColor === 'red'"
            :iconAfter="require('@/assets/images/close-red.svg')"
            :isIcon="true"
            @click="toggleMenu"
          />
        </div>
        <div class="burger__balance">
          <div v-if="isAuth && showBalance" class="header__login_balance">
            <div class="burger__balance_icon">
              <img v-if="layoutColor === 'purple'" src="@/assets/images/coins.svg" />
              <img v-if="layoutColor === 'red'" src="@/assets/images/coins-red.svg" />
            </div>
            <div v-if="userProfile" class="burger__balance_sum">
              {{
                Math.floor(userProfile?.points.points)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }}
              <span>бонусов</span>
            </div>
          </div>
        </div>
        <div class="burger__menu">
          <div v-if="userRole === 'curator'" class="burger__menu_menu">
            <MenuLink
              v-for="(item, index) in getMenuCurator"
              :burger="true"
              :key="'H' + index"
              :route="item.link"
              :title="item.name"
              :unread="item.unread"
              :active="findActiveRoute(item.link)"
              @click="toggleMenu"
            />
            <div class="header__menu_rules">
              <DownloadLink
                text="Правила участника"
                :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Curator_rules.pdf'"
                color="purple"
              />
            </div>
          </div>
          <div v-else-if="userRole === 'manager'" class="burger__menu_menu">
            <MenuLink
              v-for="(item, index) in getMenuSales"
              :burger="true"
              :key="'K' + index"
              :route="item.link"
              :title="item.name"
              :unread="item.unread"
              :active="findActiveRoute(item.link)"
              @click="toggleMenu"
            />
            <div class="header__menu_rules">
              <DownloadLink
                text="Правила участника"
                :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Sales_rules.pdf'"
                color="purple"
              />
            </div>
          </div>
          <div v-else-if="userRole === 'member' && this.userProfile && !this.userProfile.is_dealer" class="burger__menu_menu">
            <MenuLink
              v-for="(item, index) in menuRetail"
              :burger="true"
              :key="'L' + index"
              :route="item.link"
              :title="item.name"
              :unread="item.unread"
              :active="findActiveRoute(item.link)"
              @click="toggleMenu"
            />
            <div class="header__menu_rules">
              <DownloadLink
                text="Правила участника"
                :href="'https://bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Retail_rules.pdf'"
                color="purple"
              />
            </div>
          </div>
          <div v-else-if="userRole === 'member' && this.userProfile && this.userProfile.is_dealer" class="burger__menu_menu">
            <MenuLink
              v-for="(item, index) in menuRetail"
              :burger="true"
              :key="'L' + index"
              :route="item.link"
              :title="item.name"
              :unread="item.unread"
              :active="findActiveRoute(item.link)"
              @click="toggleMenu"
            />
            <div class="header__menu_rules">
              <DownloadLink
                text="Правила участника"
                :href="'https://bonus.brixogroup.com/upload/email/BRIXO_BONUS_Dealer_rules.pdf'"
                color="purple"
              />
            </div>
          </div>
          <div v-else class="burger__menu_menu">
            <MenuLink
              v-for="(item, index) in menuLinks"
              :burger="true"
              :key="'E' + index"
              :route="item.link"
              :title="item.name"
              :unread="item.unread"
              :active="findActiveRoute(item.link)"
              @click="toggleMenu"
            />
          </div>
        </div>
        <div class="burger__login">
          <div v-if="isAuth" class="burger__login_logout">
            <MainButton title="Выйти" color="yellow-round" @click="logout" />
          </div>
          <div v-else class="burger__login_button">
            <MainButton title="Войти в систему" color="yellow-round" @click="login" />
          </div>
        </div>
      </div>
    </div>
    <div class="header__login">
      <div v-if="isAuth">
        <div v-if="showBalance" class="header__login_balance">
          <div class="header__balance_title">Мой баланс:</div>
          <div class="header__balance_icon">
            <img src="@/assets/images/coins-yellow.svg" />
          </div>
          <div v-if="userProfile" class="header__balance_balance">
            {{
              Math.floor(userProfile?.points.points)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }}
          </div>
        </div>
      </div>
      <div v-else class="header__login_button">
        <MainButton title="Войти в систему" color="transparent-yellow" @click="$emit('login')" />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from "@/components/ui/Button/SwitchButton.vue";
import MenuLink from "@/components/ui/Links/MenuLink.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import DownloadLink from "@/components/ui/Links/DownloadLink.vue";
import BurgerButton from "@/components/ui/Button/BurgerButton.vue";

export default {
  name: "HeaderBar",
  components: {
    SwitchButton,
    MenuLink,
    MainButton,
    DownloadLink,
    BurgerButton,
  },
  data() {
    return {
      showMenu: false,
      menuLinks: [
        {
          link: "/",
          name: "О программе",
        },
        {
          link: "/rules/",
          name: "Правила программы",
        },
        {
          link: "/faq/",
          name: "Вопрос-ответ",
        },
        {
          link: "/brands/",
          name: "О продуктах",
        },
        {
          link: "https://brixogroup.com/catalog",
          name: "Каталог",
        },
        {
          link: "https://brixogroup.com/order",
          name: "Гарантия",
        },
        {
          link: "/news/",
          name: "Новости",
        },
        {
          link: "/promo/",
          name: "Акции",
        },
      ],
      menuCuratorSystem: [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/report/",
          name: "Загрузить отчет",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
      ],
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
    userRole() {
      return this.$store.getters["user/GET_USER_ROLE"];
    },
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    notSystem() {
      if (this.userProfile && this.userProfile.is_system) {
        return !this.userProfile.is_system;
      }
      return true;
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `header__menu_mobile_${this.layoutColor}`;
    },
    getMenuCurator() {
      if (!this.notSystem) {
        return this.menuCuratorSystem;
      }
      return this.menuCurator;
    },
    getMenuSales() {
      if (!this.notSystem) {
        return this.menuSalesSystem;
      }
      return this.menuSales;
    },
    menuCurator() {
      return [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/report/",
          name: "Загрузить отчет",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/bonus/",
          name: "Мои бонусы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
        {
          link: "/news/",
          name: "Новости",
          unread: this.userProfile?.unseen_news,
        },
        {
          link: "/promo/",
          name: "Акции",
          unread: this.userProfile?.unseen_promotions,
        },
      ];
    },
    menuSales() {
      return [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/bonus/",
          name: "Мои бонусы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
        {
          link: "/news/",
          name: "Новости",
          unread: this.userProfile?.unseen_news,
        },
        {
          link: "/promo/",
          name: "Акции",
          unread: this.userProfile?.unseen_promotions,
        },
      ];
    },
    menuSalesSystem() {
      return [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
        {
          link: "/news/",
          name: "Новости",
          unread: this.userProfile?.unseen_news,
        },
        {
          link: "/promo/",
          name: "Акции",
          unread: this.userProfile?.unseen_promotions,
        },
      ];
    },
    menuRetail() {
      return [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/purchase/",
          name: "Мои покупки",
        },
        {
          link: "/dashboard/bonus/",
          name: "Мои бонусы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
        {
          link: "https://brixogroup.com/catalog",
          name: "Каталог",
        },
        {
          link: "https://brixogroup.com/order",
          name: "Гарантия",
        },
        {
          link: "/news/",
          name: "Новости",
          unread: this.userProfile?.unseen_news,
        },
        {
          link: "/promo/",
          name: "Акции",
          unread: this.userProfile?.unseen_promotions,
        },
      ];
    },
    showBalance() {
      if (this.notSystem) {
        return true;
      } else {
        if (this.userRole === "member") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    findActiveRoute(link) {
      return link === this.$route.path ? true : false;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      document.getElementById("burger-menu").classList.toggle("header__menu_mobile_active");
      document.querySelector(".container").classList.toggle("active");
    },
    logout() {
      this.toggleMenu();
      this.$emit("logout");
    },
    login() {
      this.toggleMenu();
      this.$emit("login");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 90px 0px;
  position: relative;

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    // position: relative;

    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__logo {
    width: 178px;
    height: 100%;
    flex-shrink: 0;
    margin-right: 40px;

    &_logo {
      width: 100%;

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__switch {
    margin-bottom: 4px;
  }

  &__menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    position: relative;

    &_desktop {
      width: 100%;
    }

    &_mobile {
      display: none;
      visibility: hidden;
      // width: 352px;
      width: 320px;
      position: absolute;
      top: 0;
      right: -352px;
      background-color: $color-white;
      padding: 40px 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      transition: $transition-main;
      z-index: 3;

      &_active {
        visibility: visible;
        right: 0;
      }

      &_purple {
        & .burger__balance {
          color: $color-purple-20;
        }

        & .burger__menu_menu {
          color: $color-purple-20;
        }
      }

      &_red {
        & .burger__balance {
          color: $color-red-20;
        }

        & .burger__menu_menu {
          color: $color-red-20;
        }
      }
    }

    &_button {
      display: none;
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 4px;
    }

    &_gap {
      gap: 16px;
      margin-bottom: -14px;
    }

    &_rules {
      @include font("CeraPro", 18px, 400);
    }

    &_logout {
      width: 106px;
      height: 56px;
    }
  }

  &__login {
    width: 100%;

    &_button {
      width: 226px;
      height: 58px;
    }

    &_balance {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__balance {
    &_title {
      font-size: 20px;
      font-weight: 500;
      color: $color-white;
    }

    &_icon {
      display: flex;
      align-items: center;

      & img {
        width: 24px;
        height: 24px;
      }
    }

    &_balance {
      font-size: 24px;
      font-weight: 500;
      color: $color-yellow;
    }
  }
}

.burger {
  &__button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 28px;
    margin-bottom: 35px;

    .button {
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      transition: $transition-main;

      &:hover {
        transform: rotate(-180deg) scale(1.2);
        transition: $transition-main;
      }
    }
  }

  &__balance {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    width: 100%;
    padding-right: 28px;
    padding-bottom: 34px;
    border-bottom: 1px solid $color-lilac;
    margin-bottom: 24px;

    &_icon {
      display: flex;
      align-items: center;

      & img {
        width: 40px;
        height: 40px;
      }
    }

    &_sum {
      font-size: 24px;
      font-weight: 700;

      & span {
        display: block;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 40px;

    &_menu {
      width: 100%;
    }
  }

  &__login {
    padding-right: 28px;

    &_button {
      width: 226px;
      height: 58px;
    }

    &_logout {
      width: 106px;
      height: 58px;
    }
  }
}

@media screen and (max-width: 1530px) {
  .header {
    &__menu {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }

      &_button {
        display: block;
      }

      &_rules {
        display: flex;
        justify-content: flex-end;
        padding: 14px 0;
        padding-right: 28px;
      }
    }

    &__login {
      display: none;
    }
  }
}

@media screen and (max-width: 960px) {
  .header {
    padding: 16px 30px 0px;
  }
}

@media screen and (max-width: 410px) {
  .header {
    padding: 16px 10px 0px;
  }
}

@media screen and (max-width: 360px) {
  .header {
    &__logo {
      margin-right: 20px;
    }
  }
}
</style>
